<template>
  <div class="section section-padding bg-gray">
    <div class="container">
      <div class="section-title centered">
        <span class="subtitle">{{ productsData.product_subtitle }}</span>
        <h3 class="title">{{ productsData.products_title }}</h3>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" v-for="item in mainProducts" :key="item.id">
          <div class="sigma_service style-6">
            <div class="sigma_service-thumb">
              <img :src="getItem(item.product_image)" alt="img" />
            </div>
            <div class="sigma_service-body">
              <h5>
                <router-link :to="item.route">{{ item.title }}</router-link>
              </h5>
              <p>
                {{ item.desc[0].row }} 
              </p>
              <router-link :to="item.route" class="btn-link secondary-color">
                Read More
                <i class="far fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from '../Data/data.json'
export default {
    data(){
        return{
          productsData: '',
          mainProducts: ''
        }
    },
    created(){
      this.productsData = data.products
      this.mainProducts = data.products.main_products
    },
    methods: {
      getItem(pic){
        if(pic){
          return require('../assets/img/products/sm/' + pic)
        } else {
          return null
        }
      }
    }
}
</script>
