<template>
    <topBanner/>
    <productsindetailes/>
</template>

<script>
import topBanner from '../components/innerpagestopbanner/innerTop.vue'
import productsindetailes from '../components/productsindetailes.vue'
export default {
    components:{
        topBanner,
        productsindetailes
    },
    data(){
        return{}
    }
}
</script>